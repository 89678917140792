/**
 * @module spinner
 * @example const formSpinner = new Spinner(document.querySelector('form'));
 * formSpinner.start();
 * formSpinner.stop();
 */
export class Spinner {
    /**
     * @returns {string}
     */
    static get SPINNER_TEMPLATE() {
        return `
            <div class="spinnerContainer__backdrop"></div>
            <div class="spinnerContainer__messageContainer flex items-center align-vertical-center">
                <div class="spinner">
                    <div class="spinner__bowlRing">
                        <div class="spinner__ballHolder">
                            <div class="spinner__ball"></div>
                        </div>
                    </div>
                </div>
                <div class="spinner__message">{{ message }}</div>
            </div>
        `;
    }

    /**
     * @param {HTMLElement} target Element to add spinner
     * @param {string} [message] Text or HTML to display on the top of the spinner
     * @returns {Spinner}
     */
    static createAndStart(target, message = '') {
        const spinner = new Spinner(target);
        spinner.start(message);
        return spinner;
    }

    /**
     * A blocking element with spinner to show the page/element is busy
     * @param {HTMLElement} target Element to add spinner
     * @example
     * const spinner = new Spinner(form);
     * spinner.start('Transaction in progress. Please wait…');
     */
    constructor(target) {
        if (!(target instanceof HTMLElement)) {
            throw new Error('target is not an HTMLElement instance, can not instantiate a Spinner.');
        }
        this._target = target;
        this._containerClasses = ['spinnerContainer'];
    }

    /**
     * @param {string} [message] Text or HTML to display on the top of the spinner
     */
    start(message = '') {
        this._setupTarget();
        this._spinnerBlock = this._createSpinnerContainer(this._target, message);
    }

    /** */
    stop() {
        this._teardownTarget();

        if (!this._spinnerBlock) return;
        this._spinnerBlock.remove();
        this._spinnerBlock = null;
    }

    /**
     * @param {string} className HTML class name to add
     */
    addClass(className) {
        this._containerClasses.push(className);
    }

    /**
     * @param {HTMLElement} target Element to create container
     * @param {string} message Message to display
     * @returns {HTMLElement}
     */
    _createSpinnerContainer(target, message) {
        const spinnerContent = Spinner.SPINNER_TEMPLATE.replace('{{ message }}', message);

        const spinnerContainer = document.createElement('div');
        spinnerContainer.classList.add(...this._containerClasses);
        spinnerContainer.insertAdjacentHTML('afterbegin', spinnerContent);

        target.append(spinnerContainer);

        return spinnerContainer;
    }

    /** */
    _setupTarget() {
        this._target.classList.add('spinnerContainer__target');
    }

    /** */
    _teardownTarget() {
        this._target.classList.remove('spinnerContainer__target');
    }
}

export const PageSpinner = (function () {
    let instance;

    /**
     * @returns {Spinner} Page spinner instance
     */
    function getInstance() {
        if (!instance) {
            instance = new Spinner(document.body);
            instance.addClass('spinnerContainer--page');
        }
        return instance;
    }

    return {
        /**
         * @param {string} [message] Message to display
         */
        start: (message) => {
            getInstance().start(message);
        },
        /** */
        stop: () => {
            getInstance().stop();
        },
    };
})();
